import React, { useState } from "react";
import facebookImage from "assets/image/FB icon.png";
import blueFacebook from "assets/image2023/FB(藍色).png";
import blackFaceBook from "assets/image2023/FB_B.png";
import blueLine from "assets/image2023/LINE(藍色).png";
import blackLine from "assets/image2023/LINE_B.png";
import lineImage from "assets/image/LINE icon.png";
import catIcon from "assets/image2023/貓跳舞logo.png";
import { useNavigate } from "react-router-dom";

const styles = {
  link: "w-[40px] h-[40px] block",
};

const Navbar = () => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [iconState, setIconState] = useState({
    facebook: false,
    line: false,
  });
  const navigate = useNavigate();
  const handleClick = () => {
    setShowDropDown((showDropDown) => !showDropDown);
  };
  const handleMouseOverFace = () => {
    console.log(true);
  };
  return (
    <div className="w-full bg-[#000] shadow-nav fixed z-30 h-[50px] sm:h-[70px] flex items-center justify-between px-4 sm:px-[6.67%]">
      <div className="sm:w-[40px] sm:h-[40px] w-[30px] h-[30px]">
        <a href="https://www.dancingcats.com.tw">
          <img src={catIcon} alt="catIcon"></img>
        </a>
      </div>
      <div className=" gap-2 w-[209px] h-full hidden sm:flex">
        <div
          className="h-full w-[113px] grow-0 text-[#FFFFFF] flex items-center relative
                        justify-center text-lg font-[50%] cursor-pointer "
        >
          <div
            className={`hover:border-b hover:border-[#25F4EE] pb-1 hover:text-[#25F4EE]
            ${showDropDown && "text-[#25F4EE] border-b border-[#25F4EE]"
              } text-lg font-bold`}
            onClick={handleClick}
          >
            歷年回顧
          </div>
          <div className={`absolute top-[100%] left-0 w-[113px]`}>
            <div
              className={`bg-[#25F4EE] text-base font-medium tracking-[0.48px] 
                         text-black transition-[max-height] ease-linear 
                         duration-300 z-10 overflow-hidden
                         ${showDropDown ? "max-h-[90px] " : "max-h-0 "}
                         `}
            >
              <div
                className={`h-[35px] flex justify-center items-center hover:text-white
                transition-[visibility] ease-linear duration-300
                ${showDropDown ? "visible" : "invisible"}`}
                onClick={() => navigate("/2022")}
              >
                2022
              </div>
              <div
                className={`h-[35px] flex justify-center items-center hover:text-white
                transition-[visibility] ease-linear duration-350
                ${showDropDown ? "visible" : "invisible"}`}
                onClick={() => navigate("/2023")}
              >
                2023
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-2 items-center w-[88px]">
          <a
            href={`https://www.facebook.com/profile.php?id=100057263897477`}
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            <img
              src={iconState.facebook ? blueFacebook : facebookImage}
              alt="facebookIcon"
              onMouseOver={() => setIconState({ ...iconState, facebook: true })}
              onMouseLeave={() =>
                setIconState({ ...iconState, facebook: false })
              }
            ></img>
          </a>
          <a
            href={`https://lin.ee/7372xxV`}
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            <img
              src={iconState.line ? blueLine : lineImage}
              alt="lineIcon"
              onMouseOver={() => setIconState({ ...iconState, line: true })}
              onMouseLeave={() => setIconState({ ...iconState, line: false })}
            ></img>
          </a>
        </div>
      </div>
      <nav role="navigation" className="sm:hidden ">
        <div id="menuToggle">
          <input type="checkbox" />
          <span></span>
          <span></span>
          <span></span>

          <ul id="menu">
            <li className="text-base font-bold tracking-[0.48px] border-b border-b-black pb-1">
              歷年回顧
            </li>
            <div className="flex flex-col py-4 gap-4 items-center">
              <a href="/2022">
                <li className="text-sm tracking-[0.42px] font-medium">2022</li>
              </a>
              <a href="/2023">
                <li className="text-sm tracking-[0.42px] font-medium">2023</li>
              </a>
            </div>

            <div className="flex border-t border-t-black pt-3">
              <a
                href={`https://www.facebook.com/profile.php?id=100057263897477`}
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                <img src={blackFaceBook} alt="facebookIcon"></img>
              </a>
              <a
                href={`https://lin.ee/7372xxV`}
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                <img src={blackLine} alt="lineIcon"></img>
              </a>
            </div>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
