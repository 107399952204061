import { useRef } from "react";
import { useInView } from "react-intersection-observer";

const Video = ({ video }) => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  });
  const videoRefs = useRef();
  return (
    <div className="w-[323px] rounded-[8px]" key={video.id}>
      <div ref={ref} className="h-[757px] ">
        {inView ? (
          <video
            ref={videoRefs}
            src={`${video.url}#t=0.1`}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "8px",
            }}
            autoPlay
            muted
            loop={true}
            controls
            preload="auto"
          />
        ) : (
          <div className="w-full relative">
            <div className="loader "></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Video;
