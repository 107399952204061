import titleIcon from "assets/image/prizeicon.png";
import preArrow from "assets/image/left_btn.png";
import nextArrow from "assets/image/right_btn.png";
import SectionWrapper from "./SectionWrapper";
import { prizeData, prizeUserData } from "CONSTANTS/winnerList2023";
import Slider from "react-slick";

const styles = {
  wrapper:
    "rounded-[24px] bg-[#ffffff1a] py-[24px] sm:py-[40px] w-full px-[calc((100%-323px)/2)] sm:px-[92px] xl:px-[50px] 1340:px-[72px] mb-[40px] last:mb-0",
  section:
    "py-[24px] sm:py-[40px] first:pt-0 last:pb-0  border-b-[1px]  sm:border-b-[2px] border-[rgba(255,255,255,.3)]  last:border-none effectLink",
};
const NextArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <button
      className={`w-[40px] h-[40px] sm:w-[60px] sm:h-[60px]  z-10 ${className}`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={nextArrow} alt={"下個按鈕"} />
    </button>
  );
};
const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <button
      className={`w-[40px] h-[40px] sm:w-[60px] sm:h-[60px] ${className} z-10`}
      style={{
        ...style,
      }}
      onClick={(e) => {
        onClick(e);
      }}
    >
      <img src={preArrow} alt={"返回按鈕"} />
    </button>
  );
};

const ResultList = () => {
  const settings = {
    lazyLoad: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className="px-[3.75%] sm:px-[12.5%] text-white pb-[96px] sm:pb-[128px]">
      {/* 年度最佳商業影音 */}
      <div className={`${styles.wrapper}`}>
        <Slider {...settings}>
          {prizeData["年度最佳商業影音"].map((_, i) => (
            <SectionWrapper
              prize={"年度最佳商業影音"}
              key={i}
              companyIndex={i}
            />
          ))}
        </Slider>
      </div>
      {/* 年度最佳頭號影音 */}
      <div className={`${styles.wrapper}`}>
        <Slider {...settings}>
          {prizeData["年度最佳頭號影音"].map((_, i) => (
            <SectionWrapper
              prize={"年度最佳頭號影音"}
              key={i}
              companyIndex={i}
            />
          ))}
        </Slider>
      </div>
      {/* 年度最佳商業化音樂歌舞 */}
      <div className={`${styles.wrapper}`}>
        <Slider {...settings}>
          {prizeData["年度最佳商業化音樂歌舞"].map((_, i) => (
            <SectionWrapper
              prize={"年度最佳商業化音樂歌舞"}
              key={i}
              companyIndex={i}
            />
          ))}
        </Slider>
      </div>
      {/* 年度最佳非直立式商業素材 */}
      <div className={`${styles.wrapper}`}>
        <Slider {...settings}>
          {prizeData["年度最佳非直立式商業素材"].map((_, i) => (
            <SectionWrapper
              prize={"年度最佳非直立式商業素材"}
              key={i}
              companyIndex={i}
            />
          ))}
        </Slider>
      </div>
      {/* 年度最佳創作者 */}
      <div className={`${styles.wrapper} creator`}>
        <Slider {...settings}>
          {prizeUserData["年度最佳創作者"].map((_, i) => (
            <SectionWrapper
              prize={"年度最佳創作者"}
              isUser={true}
              companyIndex={i}
              key={i}
            />
          ))}
        </Slider>
      </div>
      {/* 年度最佳企業號 */}
      <div className={`${styles.wrapper} creator`}>
        <Slider {...settings}>
          {prizeUserData["年度最佳企業號"].map((_, i) => (
            <SectionWrapper
              prize={"年度最佳企業號"}
              isUser={true}
              companyIndex={i}
              key={i}
            />
          ))}
        </Slider>
      </div>
      {/* 年度優選品牌內容 */}
      <div className={`${styles.wrapper}`}>
        <Slider {...settings}>
          {prizeData["年度優選品牌內容"].map((_, i) => (
            <SectionWrapper
              prize={"年度優選品牌內容"}
              key={i}
              companyIndex={i}
            />
          ))}
        </Slider>
      </div>
      {/* 年度優選創作者商業合作 */}
      <div className={`${styles.wrapper}`}>
        <Slider {...settings}>
          {prizeData["年度優選創作者商業合作"].map((_, i) => (
            <SectionWrapper
              prize={"年度優選創作者商業合作"}
              key={i}
              companyIndex={i}
            />
          ))}
        </Slider>
      </div>
      {/* 年度優選創新型式廣告 */}
      <div className={`${styles.wrapper}`}>
        <Slider {...settings}>
          {prizeData["年度優選創新型式廣告"].map((_, i) => (
            <SectionWrapper
              prize={"年度優選創新型式廣告"}
              key={i}
              companyIndex={i}
            />
          ))}
        </Slider>
      </div>
      {/* 年度最佳笑果獎 */}
      <div className={`${styles.wrapper}`}>
        <Slider {...settings}>
          {prizeData["年度最佳笑果獎"].map((_, i) => (
            <SectionWrapper prize={"年度最佳笑果獎"} key={i} companyIndex={i} />
          ))}
        </Slider>
      </div>
      {/* 年度最佳探店獎 */}
      <div className={`${styles.wrapper}`}>
        <Slider {...settings}>
          {prizeData["年度最佳探店獎"].map((_, i) => (
            <SectionWrapper prize={"年度最佳探店獎"} key={i} companyIndex={i} />
          ))}
        </Slider>
      </div>
      {/* 年度最吸睛獎 */}
      <div className={`${styles.wrapper}`}>
        <Slider {...settings}>
          {prizeData["年度最吸睛獎"].map((_, i) => (
            <SectionWrapper prize={"年度最吸睛獎"} key={i} companyIndex={i} />
          ))}
        </Slider>
      </div>
    </div>
  );
};
export default ResultList;
