import { useEffect } from "react";
import Footer from "./component/Footer";
import Hero from "./component/Hero";
import ResultList from "./component/ResultList";
import Navbar from "components/Navbar";

const Home = () => {
  useEffect(() => {
    document.title = '2022貓跳舞商業化短影音案例大賞';
  }, []);
  return (
    <div>
      <Navbar />
      <section className="pt-[50px] sm:pt-[70px] bg-black">
        <Hero />
      </section>
      <div className="bg-webBackground bg-[length:100%_auto] bg-repeat-y bg-center flex flex-col items-center">
        <div className="max-w-[1440px] w-full">
          <ResultList />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
