import { useEffect, useState } from "react";

const User = ({ id }) => {
  return (
    <div className=" w-[323px] rounded-[8px]">
      {/* <blockquote
        className="tiktok-embed"
        data-unique-id={id}
        data-embed-from="embed_page"
        data-embed-type="creator"
        style={{
          maxWidth: "323px",
          margin: 0,
          borderRadius: "8px",
          minWidth: "323px",
        }}
      >
        <section></section>
      </blockquote> */}
      <iframe
        title="TikTok Profile"
        src={`https://www.tiktok.com/embed/@${id}`}
        width="100%"
        height="600px"
        frameBorder="0"
        allowFullScreen
        className="h-[387px] w-[323px] rounded-[8px]"
        loading="lazy"
      ></iframe>
    </div>
  );
};
export default User;
