export const prizeData = {
  // 年度最佳商業影音: [
  //   {
  //     id: "1-1",
  //     companyName: "台灣三星電子股份有限公司",
  //     activityName: "SAMSUNG Galaxy A53-達人專案",
  //     video: [
  //       {
  //         type: "tiktok",
  //         title: "合作達人：欸！雨群 YuChun",
  //         url: "https://www.tiktok.com/@y_chun/video/7089361753447861506",
  //         id: "7089361753447861506",
  //         height: 755,
  //       },
  //     ],
  //   },
  // ],
  年度最佳互動創意: [
    {
      id: "2-1",
      companyName: "格雷維蒂互動股份有限公司",
      activityName: "RO仙境傳說 - 魔物遠征濾鏡&達人專案",
      video: [
        {
          type: "tiktok",
          url: "https://www.tiktok.com/@anzhao.1202/video/7107549190632394011",
          id: "7107549190632394011",
          height: 739,
        },
      ],
    },
  ],
  年度最佳挑戰賽: [
    {
      id: "3-1",
      companyName: "台灣通用磨坊股份有限公司\nHäagen-Dazs",
      activityName: "#哈根達斯採蜜趣",
      video: [
        {
          type: "tiktok",
          title: "合作達人：花生米🔹",
          url: "https://www.tiktok.com/@abby_0999/video/7075645636787457306",
          id: "7075645636787457306",
          height: 739,
        },
        {
          type: "tiktok",
          title: "合作達人：毛克利的毛（毛毛）",
          url: "https://www.tiktok.com/@maokeli01/video/7075648353941409051",
          id: "7075648353941409051",
          height: 739,
        },
      ],
    },
  ],
  年度最佳頭號影音: [
    {
      id: "4-1",
      companyName: "美商雅詩蘭黛股份有限公司台灣分公司",
      activityName: "M·A·C - 雙11優惠宣傳",
      video: [
        {
          type: "video",
          title: "合作達人：下巴妹妹sister",
          url: "https://storage.googleapis.com/award-dancing-cats/6419743e661b2b839250798f.mp4",
          id: "6419743e661b2b839250798f",
        },
        {
          type: "video",
          title: "合作達人：Tiffany Yang",
          url: "https://storage.googleapis.com/award-dancing-cats/6422a9b2611104eef1e3e001.mp4",
          id: "6422a9b2611104eef1e3e001",
        },
        {
          type: "video",
          title: "合作達人：阿聲JJ Christine 🌼",
          url: "https://storage.googleapis.com/award-dancing-cats/6422a9b3a195083a6562a9e3.mp4",
          id: "6422a9b3a195083a6562a9e3",
        },
      ],
    },
  ],
  年度最佳商業化音樂歌舞: [
    {
      id: "5-1",
      companyName: "富利餐飲股份有限公司",
      activityName: "必勝客Pizza Hut - 鬥陣哈台味",
      video: [
        {
          type: "tiktok",
          title: "合作達人：Cheng4fake胡漢典",
          url: "https://www.tiktok.com/@cheng4fake60/video/7120525400798678299",
          id: "7120525400798678299",
          height: 770,
        },
      ],
    },
  ],
  年度最佳非直立式商業素材: [
    {
      id: "6-1",
      companyName: "香港商可口可樂有限公司台灣分公司",
      activityName: "Coca-Cola ZERO SUGAR",
      video: [
        {
          type: "video",
          url: "https://storage.googleapis.com/award-dancing-cats/641973d38ebc51b7b377d5b8.mp4",
          id: "641973d38ebc51b7b377d5b8",
        },
      ],
    },
  ],
  年度優選品牌內容: [
    {
      id: "11-1",
      companyName: "全聯實業股份有限公司",
      activityName: "全聯小時達-中元節宣傳",
      video: [
        {
          type: "video",
          url: "https://storage.googleapis.com/award-dancing-cats/641d4d1843bae946a32a5f2e.mp4",
          id: "641973d5d4266e91612f41a6",
        },
      ],
    },
    // {
    //   id: "11-2",
    //   companyName: "台灣三星電子股份有限公司",
    //   activityName: "SAMSUNG Galaxy Z Flip 4",
    //   video: [
    //     {
    //       type: "video",
    //       url: "https://storage.googleapis.com/award-dancing-cats/64197413d536cfeafdce90c7.mov",
    //       id: "64197413d536cfeafdce90c7",
    //     },
    //   ],
    // },
    {
      id: "11-3",
      companyName: "LINE WEBTOON",
      activityName: "LINE WEBTOON-8週年《手一滑，這話超好看！》",
      video: [
        {
          type: "video",
          url: "https://storage.googleapis.com/award-dancing-cats/64264570ee11dbd456cbd4c5.mov",
          id: "64264570ee11dbd456cbd4c5",
        },
      ],
    },
    {
      id: "11-4",
      companyName: "美商雅詩蘭黛股份有限公司台灣分公司",
      activityName: "M·A·C - 超持妝天團宣傳",
      video: [
        {
          type: "video",
          url: "https://storage.googleapis.com/award-dancing-cats/6422a972368dbebe451f3e49.mp4",
          id: "6422a972368dbebe451f3e49",
        },
      ],
    },
  ],
  年度優選創作者商業合作: [
    {
      id: "12-1",
      companyName: "青草地食品有限公司",
      activityName: "快車肉乾 X TikTok火鍋季",
      video: [
        {
          type: "tiktok",
          title: "合作達人：威廉艾米 Vlog",
          url: "https://www.tiktok.com/@william_amy_vlog/video/7182875916186553627",
          id: "7182875916186553627",
          height: 744,
        },
        {
          type: "tiktok",
          title: "合作達人：小陳的料理美學",
          url: "https://www.tiktok.com/@clauren05/video/7182900099318598917",
          id: "7182900099318598917",
          height: 757,
        },
      ],
    },
    {
      id: "12-2",
      companyName: "MSI 微星科技股份有限公司",
      activityName: "MSI Prestige 14 EVO宣傳",
      video: [
        {
          type: "tiktok",
          title: "合作達人：Nick老師",
          url: "https://www.tiktok.com/@nick_1026/video/7163212208724249857",
          id: "7163212208724249857",
          height: 735,
        },
      ],
    },
    // {
    //   id: "12-3",
    //   companyName: "台灣三星電子股份有限公司",
    //   activityName: "SAMSUNG Galaxy A53-達人專案",
    //   video: [
    //     {
    //       type: "tiktok",
    //       title: "合作達人：欸！雨群YuChun",
    //       url: "https://www.tiktok.com/@y_chun/video/7089361753447861506",
    //       id: "7089361753447861506",
    //       height: 755,
    //     },
    //   ],
    // },
    {
      id: "12-4",
      companyName: "黑松股份有限公司",
      activityName: "「自己的假自己FUN」\nairC&C揪團放暑假達人專案",
      video: [
        {
          type: "tiktok",
          title: "合作達人：Nick老師",
          url: "https://www.tiktok.com/@nick_1026/video/7121650591088053506",
          id: "7121650591088053506",
          height: 739,
        },
        {
          type: "tiktok",
          title: "合作達人：欸！雨群YuChun",
          url: "https://www.tiktok.com/@y_chun/video/7122047031958441242",
          id: "7122047031958441242",
          height: 739,
        },
        {
          type: "tiktok",
          title: "合作達人：Han（涵）",
          url: "https://www.tiktok.com/@dhdoublehan/video/7120547193001495835",
          id: "7120547193001495835",
          height: 739,
        },
        {
          type: "tiktok",
          title: "合作達人：花生米🔹",
          url: "https://www.tiktok.com/@abby_0999/video/7117568727947758874",
          id: "7117568727947758874",
          height: 739,
        },
        {
          type: "tiktok",
          title: "合作達人：派熊美食探店",
          url: "https://www.tiktok.com/@piebear_1116/video/7117197477287120155",
          id: "7117197477287120155",
          height: 739,
        },
        {
          type: "tiktok",
          title: "合作達人：愛跳舞的Diva",
          url: "https://www.tiktok.com/@diva.dance4life/video/7115342686541925659",
          id: "7115342686541925659",
          height: 739,
        },
      ],
    },
  ],
  年度優選創新型式廣告: [
    {
      id: "13-1",
      companyName: "富利餐飲股份有限公司",
      activityName: "必勝客Pizza Hut - 鬥陣哈台味",
      video: [
        {
          type: "video",
          url: "https://storage.googleapis.com/award-dancing-cats/641973d4a666708e13ee2f5b.mp4",
          id: "641973d4a666708e13ee2f5b",
        },
      ],
    },
    {
      id: "13-2",
      companyName: "台灣紅牛股份有限公司",
      activityName: "Red Bull - 台中飛行日",
      video: [
        {
          type: "video",
          url: "https://storage.googleapis.com/award-dancing-cats/6422a97ecddbbfe236d81f35.mp4",
          id: "6422a97ecddbbfe236d81f35",
        },
      ],
    },
  ],
  年度優選品效合一: [
    {
      id: "14-3",
      companyName: "台灣萊雅股份有限公司",
      activityName: "蘭蔻LANCÔME - 小黑瓶2022週年慶預熱宣傳",
      video: [
        {
          type: "tiktok",
          title: "合作達人：Tiffany Yang",
          url: "https://www.tiktok.com/@tiffany129/video/7136126214628068634",
          id: "7136126214628068634",
          height: 775,
        },
        {
          type: "tiktok",
          title: "合作達人：下巴妹妹sister",
          url: "https://www.tiktok.com/@baby0620129/video/7130936297350384922",
          id: "7130936297350384922",
          height: 739,
        },

        {
          type: "tiktok",
          title: "合作達人：羊羊",
          url: "https://www.tiktok.com/@_qqsheep/video/7133535342149242138",
          id: "7133535342149242138",
          height: 739,
        },
        {
          type: "tiktok",
          title: "合作達人：蔻里",
          url: "https://www.tiktok.com/@kori.ir/video/7137629663479942426",
          id: "7137629663479942426",
          height: 739,
        },
        {
          type: "tiktok",
          title: "合作達人：狐狸♡",
          url: "https://www.tiktok.com/@_173_ch/video/7137632316775599387",
          id: "7137632316775599387",
          height: 739,
        },
        {
          type: "tiktok",
          title: "合作達人：恩恩回來了🐑",
          url: "https://www.tiktok.com/@ynn1113/video/7133523720479591706",
          id: "7133523720479591706",
          height: 757,
        },
        {
          type: "tiktok",
          title: "合作達人：芹實我很Q🍇",
          url: "https://www.tiktok.com/@nell0102/video/7136127261631106331",
          id: "7136127261631106331",
          height: 739,
        },
        {
          type: "tiktok",
          title: "合作達人：凌妹子🌶️🌶️🌶️老闆娘",
          url: "https://www.tiktok.com/@boss.ling2022/video/7137625359046036763",
          id: "7137625359046036763",
          height: 739,
        },
        {
          type: "tiktok",
          title: "合作達人：艾莉絲🌸",
          url: "https://www.tiktok.com/@alice_wu6240/video/7130927859870289178",
          id: "7130927859870289178",
          height: 739,
        },
        {
          type: "tiktok",
          title: "合作達人：Yvonne Huang",
          url: "https://www.tiktok.com/@alice_wu6240/videhttps://www.tiktok.com/@yiyivone/video/7130934540733893914",
          id: "7130934540733893914",
          height: 739,
        },
        {
          type: "tiktok",
          title: "合作達人：💢廣末涼子的多元宇宙👊",
          url: "https://www.tiktok.com/@yvonne__1104/video/7130927946117696794",
          id: "7130927946117696794",
          height: 775,
        },
        {
          type: "tiktok",
          title: "合作達人：白菜Diva",
          url: "https://www.tiktok.com/@diva_sing_dance/video/7133525749264305435",
          id: "7133525749264305435",
          height: 757,
        },
        {
          type: "tiktok",
          title: "合作達人：Dai 代✨",
          url: "https://www.tiktok.com/@daidai_0222/video/7133525500940668186",
          id: "7133525500940668186",
          height: 739,
        },
        {
          type: "tiktok",
          title: "合作達人：阿聲JJ Christine 🌼",
          url: "https://www.tiktok.com/@christine__makeup/video/7130934181093215515",
          id: "7130934181093215515",
          height: 882,
        },
        {
          type: "tiktok",
          title: "合作達人：精神小妹🍢米可",
          url: "https://www.tiktok.com/@honeymico666/video/7136128690370399514",
          id: "7136128690370399514",
          height: 757,
        },
        {
          type: "tiktok",
          title: "合作達人：Magician Hana",
          url: "https://www.tiktok.com/@xingmagician/video/7133525368413162779",
          id: "7133525368413162779",
          height: 757,
        },
        {
          type: "tiktok",
          title: "合作達人：碗蒸",
          url: "https://www.tiktok.com/@wzhsu/video/7137626171428162842",
          id: "7137626171428162842",
          height: 739,
        },
        {
          type: "tiktok",
          title: "合作達人：雙胞胎的👭佩佩 Twins",
          url: "https://www.tiktok.com/@twinseepp/video/7137640008726367514",
          id: "7137640008726367514",
          height: 864,
        },
      ],
    },
    {
      id: "14-1",
      companyName: "台灣阿迪達斯股份有限公司",
      activityName: "adidas 2022世界盃 #這場我跟 ",
      video: [
        {
          type: "tiktok",
          title: "合作達人：James歐巴",
          url: "https://www.tiktok.com/@james_nn/video/7168461105537256730",
          id: "7168461105537256730",
          height: 745,
        },
        {
          type: "tiktok",
          title: "合作達人：Junhe赫哥",
          url: "https://www.tiktok.com/@cenjunhe/video/7168423998970187034",
          id: "7168423998970187034",
          height: 757,
        },
        {
          type: "tiktok",
          title: "合作達人：太榮/Taiei/タイエ",
          url: "https://www.tiktok.com/@rong_6666/video/7168460559774453019",
          id: "7168460559774453019",
          height: 781,
        },
        {
          type: "tiktok",
          title: "合作達人：郁欣Ivy",
          url: "https://www.tiktok.com/@ivy0710/video/7168407448179592474",
          id: "7168407448179592474",
          height: 757,
        },
        {
          type: "tiktok",
          title: "合作達人：Nick老師",
          url: "https://www.tiktok.com/@nick_1026/video/7169522878986145025",
          id: "7169522878986145025",
          height: 739,
        },
        {
          type: "tiktok",
          title: "合作達人：張琳Ilsa Chang",
          url: "https://www.tiktok.com/@ilsachang/video/7169568264190758171",
          id: "7169568264190758171",
          height: 739,
        },
        {
          type: "tiktok",
          title: "合作達人：花生米🔹",
          url: "https://www.tiktok.com/@abby_0999/video/7168432033545391387",
          id: "7168432033545391387",
          height: 739,
        },
        {
          type: "tiktok",
          title: "合作達人：Wille wang【威利王】✅",
          url: "https://www.tiktok.com/@wille_wang/video/7169918992772009242",
          id: "7169918992772009242",
          height: 757,
        },
        {
          type: "tiktok",
          title: "合作達人：家妤fish-dance",
          url: "https://www.tiktok.com/@900923fish.90/video/7168473385754971419",
          id: "7168473385754971419",
          height: 757,
        },
        {
          type: "tiktok",
          title: "合作達人：黑糖鮮茶🍼",
          url: "https://www.tiktok.com/@en_lozo/video/7168445119786405146",
          id: "7168445119786405146",
          height: 739,
        },
        {
          type: "tiktok",
          title: "合作達人：一五五小Zoe",
          url: "https://www.tiktok.com/@xuzhiwen229/video/7168832376322280731",
          id: "7168832376322280731",
          height: 757,
        },
        {
          type: "tiktok",
          title: "合作達人：Showmaîn小明",
          url: "https://www.tiktok.com/@imshowmain/video/7169972594722999578",
          id: "7169972594722999578",
          height: 757,
        },
        {
          type: "tiktok",
          title: "合作達人：Dai 代✨",
          url: "https://www.tiktok.com/@daidai_0222/video/7169535877453073690",
          id: "7169535877453073690",
          height: 739,
        },
        {
          type: "tiktok",
          title: "合作達人：Tiffany Yang",
          url: "https://www.tiktok.com/@tiffany129/video/7168462031920712986",
          id: "7168462031920712986",
          height: 739,
        },
        {
          type: "tiktok",
          title: "合作達人：🍢 楊羚 Fion",
          url: "https://www.tiktok.com/@younglingling/video/7169169450992684314",
          id: "7169169450992684314",
          height: 757,
        },
      ],
    },
    {
      id: "14-2",
      companyName: "美商雅詩蘭黛股份有限公司台灣分公司",
      activityName: "M·A·C - 雙11優惠宣傳",
      video: [
        {
          type: "tiktok",
          title: "合作達人：下巴妹妹sister",
          url: "https://www.tiktok.com/@baby0620129/video/7162100188679654682",
          id: "7162100188679654682",
          height: 757,
        },
        {
          type: "tiktok",
          title: "合作達人：阿聲JJ Christine 🌼",
          url: "https://www.tiktok.com/@christine__makeup/video/7162106640001486107",
          id: "7162106640001486107",
          height: 752,
        },
        {
          type: "tiktok",
          title: "合作達人：Tiffany Yang",
          url: "https://www.tiktok.com/@tiffany129/video/7162098729170799899",
          id: "7162098729170799899",
          height: 775,
        },
      ],
    },
  ],
};
export const prizeUserData = {
  年度最佳創作者: [
    {
      userName: "阿聲JJ Christine 🌼",
      url: "https://www.tiktok.com/@christine__makeup",
      id: "christine__makeup",
    },
    {
      userName: "欸！雨群YuChun",
      url: "https://www.tiktok.com/@y_chun",
      id: "y_chun",
    },
    {
      userName: "下巴妹妹sister",
      url: "https://www.tiktok.com/@baby0620129",
      id: "baby0620129",
    },
  ],
  年度最佳企業號: [
    {
      userName: "統一超商股份有限公司",
      url: "https://www.tiktok.com/@7eleven_tw",
      id: "7eleven_tw",
    },
  ],
};
