import titleIcon from "assets/image/prizeicon.png";
import preArrow from "assets/image/left_btn.png";
import nextArrow from "assets/image/right_btn.png";
import SectionWrapper from "./SectionWrapper";
import { prizeData, prizeUserData } from "data";
import Slider from "react-slick";

const styles = {
  wrapper:
    "rounded-[24px] bg-[#ffffff1a] py-[24px] sm:py-[40px] w-full px-[calc((100%-323px)/2)] sm:px-[92px] xl:px-[50px] 1340:px-[72px] mb-[40px] last:mb-0",
  section:
    "py-[24px] sm:py-[40px] first:pt-0 last:pb-0  border-b-[1px]  sm:border-b-[2px] border-[rgba(255,255,255,.3)]  last:border-none effectLink",
};
const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <button
      className={`w-[40px] h-[40px] sm:w-[60px] sm:h-[60px]  z-10 ${className}`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={nextArrow} alt={"下個按鈕"} />
    </button>
  );
};
const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <button
      className={`w-[40px] h-[40px] sm:w-[60px] sm:h-[60px] ${className} z-10`}
      style={{
        ...style,
      }}
      onClick={(e) => {
        onClick(e);
      }}
    >
      <img src={preArrow} alt={"返回按鈕"} />
    </button>
  );
};

const ResultList = () => {
  const settings = {
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className="px-[3.75%] sm:px-[12.5%] text-white pb-[96px] sm:pb-[128px]">
      <div className={`${styles.wrapper}`}>
        <SectionWrapper prize={"年度最佳互動創意"} />
      </div>
      <div className={`${styles.wrapper}`}>
        <Slider {...settings}>
          {prizeData["年度最佳挑戰賽"][0].video.map((item, i) => (
            <SectionWrapper prize={"年度最佳挑戰賽"} key={i} videoIndex={i} />
          ))}
        </Slider>
      </div>
      {/* 年度最佳頭號影音 */}
      <div className={`${styles.wrapper}`}>
        <Slider {...settings}>
          {prizeData["年度最佳頭號影音"][0].video.map((item, i) => (
            <SectionWrapper prize={"年度最佳頭號影音"} key={i} videoIndex={i} />
          ))}
        </Slider>
      </div>
      <div className={`${styles.wrapper}`}>
        <SectionWrapper prize={"年度最佳商業化音樂歌舞"} />
      </div>
      <div className={`${styles.wrapper}`}>
        <SectionWrapper prize={"年度最佳非直立式商業素材"} />
      </div>{" "}
      {/* 年度最佳企業號 */}
      <div className={`${styles.wrapper}`}>
        <SectionWrapper prize={"年度最佳企業號"} isUser={true} />
        {/* <SectionWrapper prize={"年度最佳商業化音樂歌舞"} /> */}
      </div>
      {/* 年度最佳創作者 */}
      <div className={`${styles.wrapper} creator`}>
        <Slider {...settings}>
          {prizeUserData["年度最佳創作者"].map((_, i) => (
            <SectionWrapper
              prize={"年度最佳創作者"}
              isUser={true}
              companyIndex={i}
              key={i}
            />
          ))}
        </Slider>
      </div>
      {/* 年度優選品牌內容 */}
      <div className={`${styles.wrapper}`}>
        <Slider {...settings}>
          {prizeData["年度優選品牌內容"].map((item, i) => (
            <SectionWrapper
              prize={"年度優選品牌內容"}
              key={i}
              companyIndex={i}
            />
          ))}
        </Slider>
      </div>
      <div className={`${styles.wrapper}`}>
        <h2
          className={`text-24px sm:text-36px flex items-center justify-center mb-[12px] xl:mb-[64px] font-black xl:hidden`}
        >
          <img
            src={titleIcon}
            alt="標題icon"
            className="w-[6px] sm:w-[9px] h-[16px] sm:h-[24px] whitespace-pre-wrap"
          />
          <span className="text-blue-1 ml-[6px] sm:ml-[10px]">
            年度優選品效合一
          </span>
        </h2>
        <div>
          <div className={styles.section}>
            <Slider {...settings}>
              {prizeData["年度優選品效合一"][0].video.map((item, i) => (
                <SectionWrapper
                  prize={"年度優選品效合一"}
                  key={i}
                  videoIndex={i}
                  companyIndex={0}
                />
              ))}
            </Slider>
          </div>
          <div className={styles.section}>
            <Slider {...settings}>
              {prizeData["年度優選品效合一"][1].video.map((item, i) => (
                <SectionWrapper
                  prize={"年度優選品效合一"}
                  key={i}
                  videoIndex={i}
                  companyIndex={1}
                />
              ))}
            </Slider>
          </div>
          <div className={`${styles.section} effectLink`}>
            <Slider {...settings}>
              {prizeData["年度優選品效合一"][2].video.map((item, i) => (
                <SectionWrapper
                  prize={"年度優選品效合一"}
                  key={i}
                  videoIndex={i}
                  companyIndex={2}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div className={`${styles.wrapper}`}>
        <h2
          className={`text-24px sm:text-36px flex items-center justify-center mb-[12px] xl:mb-[64px] font-black xl:hidden`}
        >
          <img
            src={titleIcon}
            alt="標題icon"
            className="w-[6px] sm:w-[9px] h-[16px] sm:h-[24px] whitespace-pre-wrap"
          />
          <span className="text-blue-1 ml-[6px] sm:ml-[10px]">
            年度優選創作者商業合作
          </span>
        </h2>
        <div>
          {prizeData["年度優選創作者商業合作"].map((item, companyIndex) => {
            return (
              <div className={styles.section} key={companyIndex}>
                {" "}
                <Slider {...settings}>
                  {item.video.map((video, i) => (
                    <SectionWrapper
                      prize={"年度優選創作者商業合作"}
                      key={companyIndex + "-" + i}
                      videoIndex={i}
                      companyIndex={companyIndex}
                    />
                  ))}
                </Slider>
              </div>
            );
          })}
        </div>
      </div>
      <div className={`${styles.wrapper}`}>
        <Slider {...settings}>
          {prizeData["年度優選創新型式廣告"].map((item, companyIndex) => {
            return item.video.map((video, i) => (
              <SectionWrapper
                prize={"年度優選創新型式廣告"}
                key={companyIndex + "-" + i}
                videoIndex={i}
                companyIndex={companyIndex}
              />
            ));
          })}
        </Slider>
      </div>
    </div>
  );
};
export default ResultList;
