import { prizeData, prizeUserData } from "CONSTANTS/winnerList2023";
import Video from "./Video";
import User from "./User";
import React, { Suspense, useRef } from "react";
const Tiktok = React.lazy(() => import("./Tiktok"));
const styles = {
  title:
    "text-18px sm:text-28px font-bold mb-[6px] sm:mb-[10px] whitespace-pre-wrap max-w-[238px] sm:max-w-none text-center xl:text-start",
};
const VideoSection = ({
  prize,
  companyIndex = 0,
  videoIndex = 0,
  isUser = false,
}) => {
  const componentRef = useRef();
  const companyArray = isUser ? prizeUserData[prize] : prizeData[prize];
  const companyData = companyArray[companyIndex];
  const videoData = companyData?.video?.[videoIndex];
  return (
    <Suspense fallback={<div className="h-[200px]"></div>}>
      <div
        className={`flex flex-col-reverse items-center xl:flex-row xl:items-start`}
        ref={componentRef}
      >
        <>
          {isUser ? (
            <User id={companyData.id} />
          ) : videoData.type === "tiktok" ? (
            <Tiktok
              id={videoData.id}
              url={videoData.url}
              height={videoData.height ? videoData.height : 739}
            />
          ) : (
            <Video video={videoData} />
          )}
          <div className="mb-[12px] xl:mb-0  xl:ml-[56px] flex-grow flex xl:block flex-col items-center ">
            <div
              className={`text-24px sm:text-36px flex gap-2 items-center mb-[16px] xl:mb-[64px] font-black ml-[-4px] `}
            >
              <div className="sm:pt-[6px] pt-[4px]">
                <svg
                  id="a"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 68 76"
                  style={{
                    filter:
                      "drop-shadow(0px 0px 4px rgba(255, 238, 165, 0.60))",
                  }}
                  className="sm:w-[30px] w-[20px]"
                >
                  <g id="b" />
                  <g>
                    <path
                      className="c"
                      fill="#ffeea5"
                      d="M34,76c-14.61,0-26.5-11.89-26.5-26.5s11.89-26.5,26.5-26.5,26.5,11.89,26.5,26.5-11.89,26.5-26.5,26.5Zm0-48.03c-11.87,0-21.53,9.66-21.53,21.53s9.66,21.53,21.53,21.53,21.53-9.66,21.53-21.53-9.66-21.53-21.53-21.53Z"
                    />
                    <polygon
                      className="c"
                      fill="#ffeea5"
                      points="34 35.74 38.28 44.42 47.85 45.81 40.93 52.56 42.56 62.1 34 57.6 25.44 62.1 27.07 52.56 20.15 45.81 29.72 44.42 34 35.74"
                    />
                    <g>
                      <path
                        className="c"
                        fill="#ffeea5"
                        d="M67.71,1.31c-.43-.81-1.27-1.31-2.19-1.31h-18.97c-.83,0-1.6,.41-2.06,1.1l-10.48,15.62,.2,.29c1.8,.01,3.56,.18,5.27,.47l8.4-12.52h12.99l-10.94,16.3c1.44,.83,2.82,1.76,4.11,2.8L67.58,3.87c.51-.76,.56-1.74,.13-2.55Z"
                      />
                      <path
                        className="c"
                        fill="#ffeea5"
                        d="M7.14,4.97h12.99l7.71,11.5,2.99-4.46L23.52,1.1c-.46-.69-1.23-1.1-2.06-1.1H2.48C1.57,0,.72,.51,.29,1.31c-.43,.81-.38,1.79,.13,2.55L13.99,24.09c1.29-1.04,2.66-1.97,4.1-2.8L7.14,4.97Z"
                      />
                    </g>
                  </g>
                </svg>
              </div>

              <div className="text-blue-1"> {prize} </div>
            </div>
            {isUser ? (
              <p className={styles.title}>{companyData.userName}</p>
            ) : (
              <>
                {" "}
                <p className={styles.title}>{companyData.companyName}</p>
                <p className="text-14px sm:text-20px font-medium max-w-[238px] sm:max-w-none text-center xl:text-start lg:whitespace-normal whitespace-pre-wrap">
                  {companyData.activityName}
                </p>
                {videoData.title ? (
                  <p className="text-12px sm:text-20px font-medium pt-[10px] xl:pt-[40px] max-w-[265px] sm:max-w-none text-center xl:text-start">
                    {videoData.title}
                  </p>
                ) : null}
              </>
            )}
          </div>
        </>
      </div>
    </Suspense>
  );
};

export default VideoSection;
