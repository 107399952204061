import { useRef, useState } from "react";
import { useInView } from "react-intersection-observer";

const Video = ({ video }) => {
  const [ref, inView] = useInView({
    threshold: window.innerWidth > 768 ? 0.5 : 0.2,
  });
  const videoRefs = useRef();

  return (
    <div className="w-[323px] rounded-[8px] " key={video.id}>
      <div ref={ref} className="h-[757px] w-[323px]">
        {inView ? (
          <video
            ref={videoRefs}
            src={`${video.url}#t=0.1`}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              borderRadius: "8px",
            }}
            autoPlay
            playsInline
            muted
            loop
            controls
            preload="auto"
          />
        ) : (
          <div className="w-full relative">
            <div className="loader "></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Video;
