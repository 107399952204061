let windowWidth = window.innerWidth;

export const prizeData = {
  年度最佳商業影音: [
    {
      id: "1-1",
      companyName: "國泰世華商業銀行股份有限公司",
      activityName: "智能投資",
      video: [
        {
          type: "video",
          url: "https://storage.googleapis.com/award-dancing-cats/656f1df1b8a2c1cf0ec5a4b0.mov",
          id: "656f1df1b8a2c1cf0ec5a4b0",
          height: 755,
        },
      ],
    },
    {
      id: "1-2",
      companyName: "國際富豪汽車股份有限公司",
      activityName: "VOLVO XC40",
      video: [
        {
          type: "video",
          url: "https://storage.googleapis.com/award-dancing-cats/6411997b18dd9efd848e5434.mp4",
          id: "6411997b18dd9efd848e5434",
          height: 755,
        },
      ],
    },
  ],
  年度最佳非直立式商業素材: [
    {
      id: "2-1",
      companyName: `${
        windowWidth > 768
          ? "台灣大哥大股份有限公司_GeForce\nNOW雲端遊戲服務"
          : "台灣大哥大股份有限公司 GeForce NOW雲端遊戲服務"
      }`,
      activityName: "7月影片宣傳",
      video: [
        {
          type: "video",
          url: "https://storage.googleapis.com/award-dancing-cats/6560504d9035946c142b9e7e.mp4",
          id: "6560504d9035946c142b9e7e",
          height: 739,
        },
      ],
    },
    {
      id: "2-2",
      companyName: `${
        windowWidth > 768
          ? "裕隆日產汽車股份有限公司_Nissan"
          : "裕隆日產汽車股份有限公司\nNissan"
      }`,
      activityName: "Nissan_Kicks 12月宣傳",
      video: [
        {
          type: "video",
          url: "https://storage.googleapis.com/award-dancing-cats/6580846ac885cabd549b172c.mp4",
          id: "6580846ac885cabd549b172c",
          height: 739,
        },
      ],
    },
  ],
  年度最佳笑果獎: [
    {
      id: "3-1",
      companyName: `${
        windowWidth > 768
          ? "青草地食品有限公司_快車肉乾"
          : "青草地食品有限公司\n快車肉乾"
      }`,
      activityName: "快車麻瓜",
      video: [
        {
          type: "tiktok",
          title: "合作達人：有特色的帥哥",
          url: "https://www.tiktok.com/@specsome/video/7278259578821758213",
          id: "7278259578821758213",
          height: 757,
        },
      ],
    },
    {
      id: "3-2",
      companyName: "金車股份有限公司_波爾茶",
      activityName: "旺來芭號茶宣傳",
      video: [
        {
          type: "tiktok",
          title: "合作達人：有特色的帥哥",
          url: "https://www.tiktok.com/@specsome/video/7290880714856336646",
          id: "7290880714856336646",
          height: 739,
        },
      ],
    },
  ],
  年度最吸睛獎: [
    {
      id: "4-1",
      companyName: "台灣大哥大股份有限公司",
      activityName: "myfone Campaign",
      video: [
        {
          type: "video",
          url: "https://storage.googleapis.com/award-dancing-cats/656050ca9035946c142b9e80.mp4",
          id: "656050ca9035946c142b9e80",
          height: 739,
        },
      ],
    },
    {
      id: "4-2",
      companyName: "卓昱股份有限公司",
      activityName: "ONE BOY 2023 冰鋒衣宣傳",
      video: [
        {
          type: "video",
          url: "https://storage.googleapis.com/award-dancing-cats/656d89cdb8a2c1cf0ec59664.mp4",
          id: "656d89cdb8a2c1cf0ec59664",
          height: 739,
        },
      ],
    },
  ],
  年度最佳商業化音樂歌舞: [
    {
      id: "5-1",
      companyName: `${
        windowWidth > 768
          ? "青草地食品有限公司_快車肉乾"
          : "青草地食品有限公司\n快車肉乾"
      }`,
      activityName: "快車麻瓜",
      video: [
        {
          type: "tiktok",
          title: "合作達人：赫哥",
          url: "https://www.tiktok.com/@cenjunhe/video/7276028592759328005",
          id: "7276028592759328005",
          height: 739,
        },
      ],
    },
    {
      id: "5-2",
      companyName: `${
        windowWidth > 768
          ? "聯華食品股份有限公司_可樂果"
          : "聯華食品股份有限公司\n可樂果"
      }`,
      activityName: "可樂果10月宣傳",
      video: [
        {
          type: "video",
          url: "https://storage.googleapis.com/award-dancing-cats/65808404c885cabd549b16a9.mp4",
          id: "65808404c885cabd549b16a9",
          height: 739,
        },
      ],
    },
  ],
  年度最佳頭號影音: [
    {
      id: "6-1",
      companyName: "美商美國環球影片股份有限公司台灣分公司",
      activityName: "超級瑪利歐兄弟電影版 電影宣傳",
      video: [
        {
          type: "video",
          url: "https://storage.googleapis.com/award-dancing-cats/65721c968b32494840e1ee8a.mp4",
          id: "65721c968b32494840e1ee8a",
          height: 739,
        },
      ],
    },
    {
      id: "6-2",
      companyName: "廣納國際企業股份有限公司",
      activityName: "Ora2嚕嚕米聯名限定系列",
      video: [
        {
          type: "video",
          title: "合作達人：RAiN",
          url: "https://storage.googleapis.com/award-dancing-cats/658919e62e21e7918583cdfe.mov",
          id: "658919e62e21e7918583cdfe",
        },
      ],
    },
  ],
  年度優選品牌內容: [
    {
      id: "7-1",
      companyName: "王品餐飲股份有限公司",
      activityName: "肉次方宣傳",
      video: [
        {
          type: "video",
          url: "https://storage.googleapis.com/award-dancing-cats/656050639035946c142b9e7f.mp4",
          id: "656050639035946c142b9e7f",
        },
      ],
    },
    {
      id: "7-2",
      companyName: "台灣愛普生科技股份有限公司",
      activityName: "標籤機宣傳",
      video: [
        {
          type: "video",
          url: "https://storage.googleapis.com/award-dancing-cats/658dbe68293545fb43a12276.mp4",
          id: "658dbe68293545fb43a12276",
        },
      ],
    },
  ],
  年度優選創作者商業合作: [
    {
      id: "8-1",
      companyName: `${
        windowWidth > 768
          ? "達爾膚生醫科技股份有限公司_DR.WU"
          : "達爾膚生醫科技股份有限公司\nDR.WU"
      }`,
      activityName: "保濕化妝水宣傳",
      video: [
        {
          type: "tiktok",
          title: "合作達人：妙Meow",
          url: "https://www.tiktok.com/@meowwwww66666/video/7276392639497325830",
          id: "7276392639497325830",
          height: 739,
        },
      ],
    },
    {
      id: "8-2",
      companyName: "MSI 微星科技股份有限公司",
      activityName: "618活動筆電宣傳 ",
      video: [
        {
          type: "tiktok",
          title: "合作達人：欸！雨群 YuChun",
          url: "https://www.tiktok.com/@y_chun/video/7243737424474459397",
          id: "7243737424474459397",
          height: 739,
        },
      ],
    },
    {
      id: "8-3",
      companyName: `${
        windowWidth > 768
          ? "台灣萊雅股份有限公司_適樂膚"
          : "台灣萊雅股份有限公司\n適樂膚"
      }`,
      activityName: "溫和系列達人宣傳",
      video: [
        {
          type: "tiktok",
          title: "合作達人：李川",
          url: "https://www.tiktok.com/@chuanlee666666/video/7267838739429264648",
          id: "7267838739429264648",
          height: 739,
        },
      ],
    },
  ],
  年度優選創新型式廣告: [
    {
      id: "9-1",
      companyName: "美商華納兄弟(遠東)股份有限公司台灣分公司",
      activityName: "少男少女宣傳 （滑動手勢)",
      video: [
        {
          type: "video",
          url: "https://storage.googleapis.com/award-dancing-cats/658924a3b2ae4fc65e0a3fba.mov",
          id: "658924a3b2ae4fc65e0a3fba",
        },
      ],
    },
    {
      id: "9-2",
      companyName: "美商美國環球影片股份有限公司台灣分公司",
      activityName: "玩命關頭X 宣傳",
      video: [
        {
          type: "video",
          url: "https://storage.googleapis.com/award-dancing-cats/658dbcae293545fb43a120eb.mp4",
          id: "658dbcae293545fb43a120eb",
        },
      ],
    },
  ],
  年度最佳探店獎: [
    {
      id: "11-1",
      companyName: `${
        windowWidth > 768
          ? "六月初一股份有限公司_8結蛋捲"
          : "六月初一股份有限公司\n8結蛋捲"
      }`,
      activityName: "中秋節宣傳",
      video: [
        {
          type: "tiktok",
          title: "合作達人：Nick老師",
          url: "https://www.tiktok.com/@nick_1026/video/7270476458345204993",
          id: "7270476458345204993",
          height: 739,
        },
      ],
    },
  ],
};
export const prizeUserData = {
  年度最佳創作者: [
    {
      userName: "有特色的帥哥",
      url: "https://www.tiktok.com/@specsome",
      id: "specsome",
    },
    {
      userName: "妙Meow",
      url: "https://www.tiktok.com/@meowwwww66666",
      id: "meowwwww66666",
    },
  ],
  年度最佳企業號: [
    {
      userName: "新加坡商蝦皮娛樂電商有限公司台灣分公司",
      url: "https://www.tiktok.com/@shopee_tw",
      id: "shopee_tw",
    },
    {
      userName: "安麗日用品股份有限公司",
      url: "https://www.tiktok.com/@amway_tw",
      id: "amway_tw",
    },
    {
      userName: "UNIQLO TAIWAN_台灣優衣庫有限公司",
      url: "https://www.tiktok.com/@uniqlo_taiwan",
      id: "uniqlo_taiwan",
    },
  ],
};
