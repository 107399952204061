import facebookImage from "assets/image/FB icon.png";
import lineImage from "assets/image/LINE icon.png";

const styles = {
  link: "w-[40px] h-[40px] mr-[8px] block last:mr-0",
};

const Footer = () => {
  return (
    <footer className=" pt-[18px] sm:pt-[16px] pb-[45px] sm:pb-[52px] flex flex-col sm:flex-row justify-center items-center border-t-[1px] sm:border-t-[2px] border-[rgba(255,255,255,.3)] mx-[13px] sm:mx-[80px] w-[92.08%] sm:w-[88.89%]">
      <p className="text-14px text-white font-medium sm:mr-[56px] mb-[14px] sm:mb-0">
        貓跳舞移動有限公司
      </p>
      <div className="flex">
        <a
          href={`https://www.facebook.com/profile.php?id=100057263897477`}
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          <img src={facebookImage} alt="facebookIcon"></img>
        </a>
        <a
          href={`https://lin.ee/7372xxV`}
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          <img src={lineImage} alt="lineIcon"></img>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
