// import Tiktok from "./Tiktok";
import titleIcon from "assets/image/prizeicon.png";
import { prizeData, prizeUserData } from "data";
import Video from "./Video";
import User from "./User";
import React, { Suspense, useRef } from "react";
const Tiktok = React.lazy(() => import("./Tiktok"));
const styles = {
  title:
    "text-18px sm:text-28px font-bold mb-[6px] sm:mb-[10px] whitespace-pre-wrap max-w-[238px] sm:max-w-none text-center xl:text-start",
};
const VideoSection = ({
  prize,
  companyIndex = 0,
  videoIndex = 0,
  isUser = false,
}) => {
  const componentRef = useRef();
  const companyArray = isUser ? prizeUserData[prize] : prizeData[prize];
  const companyData = companyArray[companyIndex];
  const videoData = companyData?.video?.[videoIndex];
  return (
    <Suspense fallback={<div className="h-[200px]"></div>}>
      <div
        className={`flex  flex-col-reverse items-center xl:flex-row xl:items-start  `}
        ref={componentRef}
      >
        <>
          {isUser ? (
            <User id={companyData.id} />
          ) : videoData.type === "tiktok" ? (
            <Tiktok
              id={videoData.id}
              url={videoData.url}
              height={videoData.height ? videoData.height : 739}
            />
          ) : (
            <Video video={videoData} />
          )}
          <div className="mb-[12px] xl:mb-0  xl:ml-[56px] flex-grow flex xl:block flex-col items-center ">
            <h2
              className={`text-24px sm:text-36px flex items-center mb-[16px] xl:mb-[64px] font-black ${
                prize === "年度優選品效合一" ||
                prize === "年度優選創作者商業合作"
                  ? "hidden xl:flex"
                  : null
              }`}
            >
              <img
                src={titleIcon}
                alt="標題icon"
                className="w-[6px] sm:w-[9px] h-[16px] sm:h-[24px] whitespace-pre-wrap"
              />
              <span className="text-blue-1 ml-[6px] sm:ml-[10px]">
                {" "}
                {prize}{" "}
              </span>
            </h2>
            {isUser ? (
              <p className={styles.title}>{companyData.userName}</p>
            ) : (
              <>
                {" "}
                <p className={styles.title}>{companyData.companyName}</p>
                <p className="text-14px sm:text-20px font-medium max-w-[238px] sm:max-w-none text-center xl:text-start lg:whitespace-normal whitespace-pre-wrap">
                  {companyData.activityName}
                </p>
                {videoData.title ? (
                  <p className="text-12px sm:text-20px font-medium pt-[10px] xl:pt-[40px] max-w-[265px] sm:max-w-none text-center xl:text-start">
                    {videoData.title}
                  </p>
                ) : null}
              </>
            )}
          </div>
        </>
      </div>
    </Suspense>
  );
};

export default VideoSection;
